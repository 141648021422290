import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import PriceIcon from './PriceIcon';
import DeliveryIcon from './DeliveryIcon';
import DeliveryReturnIcon from './DeliveryReturnIcon';
import css from './SectionServices.module.css';

const PRICE_SERVICE_ID = 'price';
const DELIVERY_SERVICE_ID = 'delivery';
const DELIVERY_RETURN_SERVICE_ID = 'delivery-return';

const serviceIcon = id => {
  let icon;

  if (id === PRICE_SERVICE_ID) {
    icon = <PriceIcon className={css.serviceIcon} />;
  } else if (id === DELIVERY_SERVICE_ID) {
    icon = <DeliveryIcon className={css.serviceIcon} />;
  } else if (id === DELIVERY_RETURN_SERVICE_ID) {
    icon = <DeliveryReturnIcon className={css.serviceIcon} />;
  }

  return icon;
};

const serviceItem = (id, headingTranslationId, textTranslationId, textSecondLineTranslationId) => (
  <div className={css.service}>
    {serviceIcon(id)}
    <h3 className={css.serviceHeading}>
      <FormattedMessage id={headingTranslationId} />
    </h3>
    <div className={css.serviceTextContainer}>
      <p className={css.serviceText}>
        <FormattedMessage id={textTranslationId} />
      </p>
      {/* <p className={css.serviceText}>
        <FormattedMessage id={textSecondLineTranslationId} />
      </p> */}
    </div>
  </div>
);

const SectionServices = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.services}>
        {serviceItem(
          PRICE_SERVICE_ID,
          'LandingPage.SectionServices.firstHeading',
          'LandingPage.SectionServices.firstText',
          'LandingPage.SectionServices.firstTextSecondLine'
        )}
        {serviceItem(
          DELIVERY_SERVICE_ID,
          'LandingPage.SectionServices.secondHeading',
          'LandingPage.SectionServices.secondText',
          'LandingPage.SectionServices.secondTextSecondLine'
        )}
        {serviceItem(
          DELIVERY_RETURN_SERVICE_ID,
          'LandingPage.SectionServices.thirdHeading',
          'LandingPage.SectionServices.thirdText',
          'LandingPage.SectionServices.thirdTextSecondLine'
        )}
      </div>
    </div>
  );
};

SectionServices.defaultProps = { rootClassName: null, className: null };

SectionServices.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionServices;
