import React from 'react';
import { any, string } from 'prop-types';
import classNames from 'classnames';

import css from './LandingPage.module.css';

const FullWidthWrapper = props => {
  const { className, children } = props;
  const classes = classNames(css.fullWidthWrapper, className);
  return <div className={classes}>{children}</div>;
};

FullWidthWrapper.defaultProps = {
  className: null,
  children: null,
};

FullWidthWrapper.propTypes = {
  className: string,
  children: any,
};

export default FullWidthWrapper;
