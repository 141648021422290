import React from 'react';
import PropTypes from 'prop-types';

const DeliveryIcon = props => {
  const { className, ...rest } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="-1.25 -1.25 60 60"
      {...rest}
    >
      <path d="M55.751 40.49V28.75l-5.87-9.392h-8.94l.723-9.391H6.444L4.098 40.49H55.75Z" />
      <path d="m22.88 44.598-4.696 2.935-4.695-2.935v-5.87l4.695-2.934 4.696 2.935v5.87Z" />
      <path
        fill="#ff8c00"
        d="M46.36 28.75h9.391l-4.41-7.058-4.982 7.058ZM22.88 38.729v5.87l-4.696 2.934-4.695-2.935 9.391-5.87ZM51.055 40.49v4.108l-4.696 2.935-4.695-2.935V40.49h9.391ZM4.097 40.49h9.392v-1.761l4.695-2.935H4.458l-.361 4.696Z"
      />
      <path fill="#ff8c00" d="m18.184 35.794 4.696 2.935v1.76h32.871v-4.695H18.184Z" />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="m45.185 19.358 1.174 9.392h9.392M55.751 35.794h-4.696M27.576 35.794h12.101M1.749 35.794h7.044M13.489 40.49H4.097L6.445 9.967h35.219L39.316 40.49H22.88"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M39.316 40.49H55.75V28.75l-5.87-9.392h-8.94"
      />
      <path
        stroke="#4a4a4a"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="m22.88 44.598-4.696 2.935-4.695-2.935v-5.87l4.695-2.934 4.696 2.935v5.87ZM51.055 40.49v4.108l-4.696 2.935-4.695-2.935V40.49"
      />
    </svg>
  );
};

DeliveryIcon.defaultProps = {
  className: null,
};

const { string } = PropTypes;

DeliveryIcon.propTypes = {
  className: string,
};

export default DeliveryIcon;
