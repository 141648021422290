import React from 'react';
import PropTypes from 'prop-types';

const PriceIcon = props => {
  const { className, ...rest } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="-1.25 -1.25 60 60"
      {...rest}
    >
      <path d="M25.075 11.6 1.8 34.912 22.588 55.7 45.9 32.425V11.6H25.075ZM41 23.85h-7.35V16.5H41v7.35Z" />
      <path
        fill="#ff8c00"
        d="M41 16.5v7.35h-7.35L12.213 45.287 22.625 55.7 45.9 32.425V11.6L41 16.5Z"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M38.55 18.95 50.8 6.7M53.25 4.25 55.7 1.8M30.384 32.312l-4.331-4.331-4.33.867-.867 4.332 3.464 3.464-.864 4.331-4.332.865-4.332-4.331M27.787 29.713l1.732-1.732M15.66 41.84l1.732-1.732M41 16.5h-7.35v7.35H41V16.5Z"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M22.588 55.7 1.8 34.912 25.075 11.6H45.9v20.825L22.588 55.7Z"
      />
    </svg>
  );
};

PriceIcon.defaultProps = {
  className: null,
};

const { string } = PropTypes;

PriceIcon.propTypes = {
  className: string,
};

export default PriceIcon;
