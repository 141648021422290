import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import config from '../../../config';
import classNames from 'classnames';

import css from './SectionIntro.module.css';

const SectionIntro = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage
          id="LandingPage.SectionIntro.title"
          values={{ siteTitle: config.siteTitle }}
        />
      </h1>
      <NamedLink className={css.linkButton} name="NewListingPage">
        <FormattedMessage
          id="LandingPage.SectionIntro.buttonText"
          values={{ siteTitle: config.siteTitle }}
        />
      </NamedLink>
    </div>
  );
};

SectionIntro.defaultProps = { rootClassName: null, className: null };

SectionIntro.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionIntro;
