import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import classNames from 'classnames';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const highlightedText = (
    <span>
      <br />
      <span className={css.highlightedText}>
        <FormattedMessage id="LandingPage.SectionHero.highlighted" />
      </span>
    </span>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage
          id="LandingPage.SectionHero.title"
          values={{
            highlightedText,
          }}
        />
      </h1>
      <NamedLink className={css.linkButton} name="BuyerSignupPage">
        <FormattedMessage id="LandingPage.SectionHero.buttonText" />
      </NamedLink>
      <p className={css.text}>
        <FormattedMessage id="LandingPage.SectionHero.shippingText" />
      </p>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
