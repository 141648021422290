import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';
import config from '../../../config';
import classNames from 'classnames';

import css from './SectionSellOnPetspace.module.css';

const featureItem = translationId => (
  <div className={css.feature}>
    <FormattedMessage id={translationId} />
  </div>
);

const SectionSellOnPetSpace = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage
          id="LandingPage.SectionSellOnPetspace.title"
          values={{ siteTitle: config.siteTitle }}
        />
      </h1>
      <div className={css.features}>
        {featureItem('LandingPage.SectionSellOnPetspace.firstFeature')}
        {featureItem('LandingPage.SectionSellOnPetspace.secondFeature')}
        {featureItem('LandingPage.SectionSellOnPetspace.thirdFeature')}
      </div>
      <NamedLink className={css.linkButton} name="NewListingPage">
        <FormattedMessage id="LandingPage.SectionSellOnPetspace.buttonText" />
      </NamedLink>
    </div>
  );
};

SectionSellOnPetSpace.defaultProps = { rootClassName: null, className: null };

SectionSellOnPetSpace.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSellOnPetSpace;
