import React, { Component, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';

import { NamedLink } from '../../../components';

import css from './SectionNewBrands.module.css';

import config from '../../../config';
import NoImageIcon from '../../../components/ResponsiveImage/NoImageIcon';
import { getBrandSellers } from '../../../util/api';

// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Create a "card" that contains a link to filtered search on SearchPage.
const FilterLink = props => {
  const { name, image, uuid } = props;
  const nameText = <span className={css.searchName}>{name}</span>;
  return (
    <NamedLink name="ProfilePage" params={{ id: uuid }} className={css.searchLink}>
      {image ? (
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage src={image} alt={name} className={css.searchImage} />
          </div>
        </div>
      ) : (
        <div className={css.linkText}>
          {/* circule with no image */}
          <FormattedMessage id="SectionNewBrands.filteredSearch" values={{ filter: nameText }} />
        </div>
      )}
    </NamedLink>
  );
};

// {
//   "uuid": "6720ddbd-8214-4265-9f88-8a93bf7f8b63",
//   "type": "user",
//   "state": "active",
//   "userType": "seller",
//   "businessName": "Pet Vits",
//   "isBrandSeller": true,
//   "square-small2x": {
//       "height": 480,
//       "width": 480,
//       "url": "https://sharetribe.imgix.net/65683e72-7b29-4255-b7dc-1bbc119092ab/6720e657-e206-4c8c-9525-d14203b8e01b?auto=format&crop=edges&fit=crop&h=480&w=480&s=2200352f617fb894cc92ff296a2187bc",
//       "name": "square-small2x"
//   }
// }

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionNewBrands = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const brands = config.custom.filters.find(f => f.id === 'brand').config.options ?? [];
  const hasBrands = brands.length > 0;

  const [brandSellers, setBrandSellers] = React.useState([]);

  useEffect(() => {
    const fetchBrandSellers = async () => {
      try {
        const response = await getBrandSellers();
        if (response?.data?.length > 0) {
          setBrandSellers(response.data);
        }
      } catch (error) {
        console.error('Error fetching brand sellers:', error);
      }
    };

    fetchBrandSellers();
  }, []);

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  return hasBrands ? (
    <div className={css.container}>
      <div className={css.title}>
        <FormattedMessage id="SectionNewBrands.title" />
      </div>
      <div className={css.filteredSearches}>
        {React.Children.toArray(
          brandSellers.map((brand, i) => (
            <FilterLink
              key={i}
              className
              name={brand.businessName}
              image={brand?.['square-small2x']?.url}
              uuid={brand.uuid}
            />
          ))
        )}
      </div>
    </div>
  ) : null;
};

SectionNewBrands.defaultProps = { rootClassName: null, className: null };

SectionNewBrands.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNewBrands;
