import React from 'react';
import PropTypes from 'prop-types';

const DeliveryReturnIcon = props => {
  const { className, ...rest } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="-1.25 -1.25 60 60"
      {...rest}
    >
      <path d="M28.75 1.198 1.198 10.78v33.542l26.354 11.98 28.75-11.98V10.78L28.75 1.198Z" />
      <path
        fill="#ff8c00"
        d="m42.227 5.887-27.852 9.686 13.177 4.792v35.937l28.75-11.98v-33.54L42.227 5.886Z"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="m14.375 15.573 27.852-9.686M34.74 38.333v8.386M31.146 41.927l3.594-3.594 2.994 2.995M44.323 34.74v8.385M40.73 38.333l3.593-3.593 2.995 2.994"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M56.302 10.781v33.542l-28.75 11.98-26.354-11.98V10.78M27.552 20.365v35.937"
      />
      <path
        stroke="#4a4a4a"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M27.552 20.365 1.198 10.78 28.75 1.198l27.552 9.583-28.75 9.584Z"
      />
    </svg>
  );
};

DeliveryReturnIcon.defaultProps = {
  className: null,
};

const { string } = PropTypes;

DeliveryReturnIcon.propTypes = {
  className: string,
};

export default DeliveryReturnIcon;
