import React from 'react';
import { string, bool, arrayOf } from 'prop-types';
import { propTypes } from '../../../util/types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ListingCard, NamedLink, IconArrow } from '../../../components';

import css from './SectionFeaturedListings.module.css';

const MINIMUM_LISTING_COUNT = 0;

const SectionFeaturedListings = props => {
  const { rootClassName, className,queryListingsInProgress, queryListingsError } = props;

  const listings = props.listings.filter(l => l.attributes.publicData.featured)
  // sort by l.attributes.publicData.featuredOrder highest number to lowest, the undefined listing will stay be at the last
    .sort((a, b) =>
      a.attributes.publicData.featuredOrder === undefined
        ? 1
        : b.attributes.publicData.featuredOrder === undefined
          ? -1
        : b.attributes.publicData.featuredOrder - a.attributes.publicData.featuredOrder
      
    )

  const classes = classNames(rootClassName || css.root, className);

  // Remove SectionFeaturedListings if querying listings is in
  // progress, of if there's any error.
  if (queryListingsInProgress || queryListingsError) {
    return null;
  }

  const hasListings = listings.length > MINIMUM_LISTING_COUNT;

  return hasListings ? (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFeaturedListings.title" />
      </div>
      <div className={css.listingCards}>
        {listings
          .map(l => {
          return <ListingCard key={l.id.uuid} listing={l} showAuthorInfo rootClassName={css.listingCard} />;
        })}
      </div>
    </div>
  ) : null;
};

SectionFeaturedListings.defaultProps = {
  rootClassName: null,
  className: null,
  queryListingsInProgress: false,
  queryListingsError: null,
};

SectionFeaturedListings.propTypes = {
  rootClassName: string,
  className: string,

  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
};

export default SectionFeaturedListings;
